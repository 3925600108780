/* eslint-disable no-console */
import { getServerAdress } from "./utils";

export const KOR_API = {};
console.log("*** KOR-NODE server:", getServerAdress());
console.log("*** KOR-NODE mode:", process.env.NODE_ENV);

export const KOR_API_PRIVATE = {};

global.KOR_API = KOR_API;

function requireAll(r) {
  r.keys().forEach(r);
}
requireAll(require.context("./", true, /\.js$/));
