import { KOR_API_PRIVATE, KOR_API } from "./entry";

KOR_API.ACTION_TYPES = {
  HTML_TO_PDF: "html/pdf",
  JSON_LATEX_SINIAT: "json/latex/siniat",
  SVG_GROUPS: "svg/groups",
  SVG_INCLUDE_ONLY_GROUPS: "svg/include-only-groups",
  SVG_EXCLUDE_GROUPS: "svg/exclude-groups",
  SVG_DXF: "svg/dxf",
  DWG_SVG: "dwg/svg",
  MAIL: "send/mail",
  UPLOAD: "send/upload",
};

KOR_API.doAction = async ({ action, data }) => {
  let oRes = {};
  switch (action) {
    case KOR_API.ACTION_TYPES.HTML_TO_PDF: {
      oRes = await KOR_API_PRIVATE.htmlPdf(data);
      break;
    }
    case KOR_API.ACTION_TYPES.JSON_LATEX_SINIAT: {
      oRes = await KOR_API_PRIVATE.jsonLatexSiniat(data);
      break;
    }
    case KOR_API.ACTION_TYPES.SVG_GROUPS: {
      oRes = await KOR_API_PRIVATE.svgGroups(data);
      break;
    }
    case KOR_API.ACTION_TYPES.DWG_SVG: {
      oRes = await KOR_API_PRIVATE.dwgSvg(data);
      break;
    }
    case KOR_API.ACTION_TYPES.SVG_INCLUDE_ONLY_GROUPS: {
      oRes = await KOR_API_PRIVATE.svgIncludeOnlyGroups(data);
      break;
    }
    case KOR_API.ACTION_TYPES.SVG_EXCLUDE_GROUPS: {
      oRes = await KOR_API_PRIVATE.svgExcludeGroups(data);
      break;
    }
    case KOR_API.ACTION_TYPES.SVG_DXF: {
      oRes = await KOR_API_PRIVATE.svgDxf(data);
      break;
    }
    case KOR_API.ACTION_TYPES.MAIL: {
      oRes = await KOR_API_PRIVATE.mail(data);
      break;
    }
    case KOR_API.ACTION_TYPES.UPLOAD: {
      oRes = await KOR_API_PRIVATE.upload(data);
      break;
    }
    default: {
      // eslint-disable-next-line no-console
      throw new Error(
        `The KOR_API action type that you have provided does not exist ! Please provide a valid one`
      );
    }
  }

  return oRes;
};
