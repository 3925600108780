import fetch from "node-fetch";
import FormData from "form-data";

import { getServerAdress } from "../utils";

export const REQUEST_TYPE = {
  POST_FORM_DATA: "post/formdata",
  POST_JSON: "post/json",
  POST_MAIL: "post/mail",
  POST_SVG_EXCLUDE_GROUPS: "post/excludeGroups",
  POST_SVG_INCLUDE_GROUPS: "post/includeGroups",
  POST_UPLOAD: "post/upload",
};

export const makeRequest = async (props, reqPath, reqType) => {
  if (!props || !reqPath || !reqType)
    throw new Error("one of makeRequest() params is undefined");

  let body;
  let queryParams = "";
  let contentType;

  switch (reqType) {
    case REQUEST_TYPE.POST_FORM_DATA:
      body = new FormData();
      Object.entries(props).forEach(([key, value]) => {
        body.append(key, value);
      });
      break;
    case REQUEST_TYPE.POST_JSON:
      body = JSON.stringify(props);
      contentType = "application/json";
      break;
    case REQUEST_TYPE.POST_MAIL:
    case REQUEST_TYPE.POST_UPLOAD: {
      const { files, ...otherProps } = props;
      body = new FormData();

      for (let i = 0; i < files.length; i += 1) {
        body.append("files", files[i]);
      }

      if (otherProps) {
        Object.entries(otherProps).forEach(([key, value]) => {
          body.append(key, value);
        });
      }
      break;
    }
    case REQUEST_TYPE.POST_SVG_INCLUDE_GROUPS:
    case REQUEST_TYPE.POST_SVG_EXCLUDE_GROUPS: {
      const { svgFile, groups } = props;

      body = new FormData();
      body.append("svgFile", svgFile);

      let groupsParsed = "";
      groups.forEach((e) => {
        groupsParsed += e;
        groupsParsed += "%00";
      });
      queryParams = `?groups=${groupsParsed}`;
      break;
    }
    default:
      throw new Error("Unknown REQUEST_TYPE");
  }

  return new Promise((resolve, reject) => {
    (async () => {
      const myHeaders = new fetch.Headers();
      if (contentType) {
        myHeaders.append("Content-Type", contentType);
      }
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body,
        redirect: "follow",
      };

      try {
        const server = getServerAdress();
        const response = await fetch(
          `${server}/${reqPath}${queryParams}`,
          requestOptions
        );
        const oRes = await response.json();
        resolve(oRes);
      } catch (err) {
        reject(err);
      }
    })();
  });
};
