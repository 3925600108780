import { makeRequest, REQUEST_TYPE } from "./request";
import { KOR_API_PRIVATE } from "../entry";

KOR_API_PRIVATE.svgDxf = async (props) =>
  makeRequest(props, "svg/dxf", REQUEST_TYPE.POST_FORM_DATA);

KOR_API_PRIVATE.svgIncludeOnlyGroups = async (props) =>
  makeRequest(
    props,
    "svg/include-only-groups",
    REQUEST_TYPE.POST_SVG_INCLUDE_GROUPS
  );
KOR_API_PRIVATE.svgGroups = async (props) =>
  makeRequest(props, "svg/groups", REQUEST_TYPE.POST_FORM_DATA);

KOR_API_PRIVATE.svgExcludeGroups = async (props) =>
  makeRequest(
    props,
    "svg/exclude-groups",
    REQUEST_TYPE.POST_SVG_EXCLUDE_GROUPS
  );
